import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import JunioCard from "../../images/junio-card.png";
import AtmWithdraw from "../../images/atm_withdraw_img.png";
import FlatDiscount from "../../images/flat_discount_img.png";
import ZeroFeeDiscount from "../../images/zero_fee_discount.png";
import sticker1Full from "../../images/c4-sticker-full.png";
import sticker2 from "../../images/c4-sticker2.png";
const benefits = [
  {
    img: ZeroFeeDiscount,
    text: "Zero Annual Fee",
  },
  { img: FlatDiscount, text: "Flat 1% Cashback* on spends" },
  { img: AtmWithdraw, text: "ATM Withdrawals" },
];
class C5 extends Component {
  anim() {
    if (window !== undefined) {
      const simpleParallax = require("simple-parallax-js");
      var image = document.getElementsByClassName("c4-sticker2");
      new simpleParallax(image, {
        orientation: "right",
        overflow: true,
        maxTransition: 80,
      });
      var image2 = document.getElementsByClassName("c4-sticker1");
      new simpleParallax(image2, {
        orientation: "left",
        overflow: true,
        maxTransition: 60,
        delay: 0,
      });
    }
  }
  componentDidMount() {
    this.anim();
  }

  componentDidUpdate() {
    this.anim();
  }

  render() {
    if (!this.props.data) return <></>;
    return (
      <React.Fragment>
        <Container
          fluid
          className="c3 c-pad pb-5 card_section_container"
          style={{ position: "relative" }}
        >
          <Col className="d-none d-md-block">
            <img
              alt=""
              className="c4-sticker2"
              src={sticker2}
              height="64px"
              width="auto"
              style={{ zIndex: 1, left: 140 }}
            />
            <img
              alt=""
              className="c4-sticker1"
              src={sticker1Full}
              height="332px"
              width="auto"
              style={{ zIndex: 1, right: 200, top: 30 }}
            />
            <div className="junio_card_img_container">
              <img src={JunioCard} className="junio_card_img" />
            </div>
            <h1 className="pre_approve_text c4-h1">
              {"Pre-Approved Rupay Smart card \nfor Students"}
            </h1>
            <div className="benefits_badge_list">
              {benefits.map((item) => (
                <div className="benefits_badge_container">
                  <div className="benefits_badge_img_container">
                    <img src={item.img} className="benefits_badge_img" />
                  </div>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
            <div className="smart_card_link">
              <a href="https://junio.in/app/card-order" target="_blank">
                <Button
                  className="c5-button btn-pri"
                  style={{
                    height: "64px",
                    paddingInline: "52px",
                    marginBottom: "40px",
                  }}
                >
                  Get your Junio Smart Card
                </Button>
              </a>
            </div>
          </Col>
          <Col className="d-md-none">
            <img
              alt=""
              className="c4-sticker2"
              src={sticker2}
              height="64px"
              width="auto"
              style={{ zIndex: 1, left: -100 }}
            />
            <img
              alt=""
              className="c4-sticker1"
              src={sticker1Full}
              height="332px"
              width="auto"
              style={{ zIndex: 1, right: 10, top: 0, marginTop: 0 }}
            />
            <div className="junio_card_img_container">
              <img src={JunioCard} className="img-fluid junio_card_img" />
            </div>
            <h1 className="pre_approve_text c4-h1">
              {"Pre-Approved Rupay Smart\ncard for Students"}
            </h1>
            <div className="benefits_badge_list">
              {benefits.map((item) => (
                <div className="benefits_badge_container">
                  <div className="benefits_badge_img_container">
                    <img src={item.img} className="benefits_badge_img" />
                  </div>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
            <div className="smart_card_link">
              <a href="https://junio.in/app/card-order" target="_blank">
                <Button className="c5-button btn-pri">
                  Get your Junio Smart Card
                </Button>
              </a>
            </div>
          </Col>
        </Container>
      </React.Fragment>
    );
  }
}

export default C5;
